<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import userStore from '@/state/store'

export default {
  name: "CreateInquiry",
  emits: ['created'],
  data() {
    return {
      showModal: false,
      inquiry: {
        text: '',
        description: '',
        sender_id: null,
        sales_manager_id: null,
        status: 'pending',
        customer: '',
        is_new_client: false
      },
      editor: ClassicEditor,
      sender_select: {
        options: [],
        searchable: true,
        placeholder: 'Select sender'
      },
      sales_manager_select: {
        options: [],
        searchable: true,
        placeholder: 'Select manager'
      },
      is_saving: false
    }
  },
  computed: {
    user() {
      return userStore.state.user
    }
  },
  components: {
    ckeditor: CKEditor.component,
    Multiselect
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    async createInquiry() {
      let Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
      this.is_saving = true;
      try {
        await axios.post(`/inquiry/create/`, {
          sender_id: this.inquiry.sender_id,
          text: this.inquiry.text,
          description: this.inquiry.description,
          status: 'pending',
          sales_manager_id: this.inquiry.sales_manager_id,
          client: this.inquiry.customer,
          is_new_customer: this.inquiry.is_new_client
        })
        this.$emit('created')
        this.is_saving = false;
        this.showModal = false;
        await Swal.fire({
          icon: 'success',
          showConfirmButton: false,
          timer: 10000,
          title: 'Inquiry created successfully',
        })
      } catch {
        this.is_saving = false;
        await Toast.fire({
          icon: "error",
          title: "Error while creating inquiry",
        });
      }
    },
    async asyncFindUsers(query) {
      try {
        if (!query || query.trim().length <= 0) return [];
        let response = await axios.get("/user/", {
          params: {
            username: query.trim(),
          },
        });
        return response.data.results.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });
      } catch {
        return []
      }
    },
    async asyncFindSender(query) {
      this.sender_select.options = await this.asyncFindUsers(query)
    },
    async asyncFindSalesManagers(query) {
      this.sales_manager_select.options = await this.asyncFindUsers(query)
    }
  },
  mounted() {
    if (this.user.role.toLowerCase() !== 'admin') {
      this.sender_select.options = [{ value: this.user.id, label: this.user.full_name }]
      this.sales_manager_select.options = [{ value: this.user.id, label: this.user.full_name }]

      this.inquiry.sender_id = this.user.id
      this.inquiry.sales_manager_id = this.user.id
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="New Inquiry" centered hide-footer size="lg">
    <b-row v-if="inquiry" gutter-y="3">
      <b-col sm="12">
        <label class="form-label">Inquiry Message</label>
        <ckeditor ref="inquiry_text_editor" v-if="inquiry" v-model="inquiry.text" :editor="editor"></ckeditor>
      </b-col>
      <b-col sm="12">
        <label class="form-label">Comment</label>
        <ckeditor v-model="inquiry.description" :editor="editor"></ckeditor>
      </b-col>
      <b-col sm="12">
        <label class="form-label">Customer</label>
        <input v-model="inquiry.customer" class="form-control" placeholder="Type customer name">
      </b-col>
      <b-col sm="12">
        <div class="form-check form-check-success">
          <input v-model="inquiry.is_new_client" class="form-check-input" type="checkbox">
          <label @click="inquiry.is_new_client = !inquiry.is_new_client" class="form-check-label">
            Is a new Customer
          </label>
        </div>
        <div v-if="inquiry.is_new_client" class="mt-2">
          <small class="text-body">
            <span class="text-danger">Reminder: </span> Selecting this option boosts your Lead Generation KPI score.
            Be sure the customer is new. If it is later determined that the customer already exists,
            the bonus associated with this KPI will not be awarded.
          </small>
        </div>
      </b-col>
      <b-col lg="6">
        <label class="form-label">Sender</label>
        <Multiselect v-model="inquiry.sender_id" v-bind="sender_select" @search-change="asyncFindSender"
          v-if="user.role.toLowerCase() === 'admin'" />
        <Multiselect v-model="inquiry.sender_id" v-bind="sender_select" v-else :disabled="true" />
      </b-col>
      <b-col lg="6">
        <label class="form-label">Sales Manager</label>
        <Multiselect v-model="inquiry.sales_manager_id" v-bind="sales_manager_select"
          @search-change="asyncFindSalesManagers" v-if="user.role.toLowerCase() === 'admin'" />
        <Multiselect v-else v-model="inquiry.sales_manager_id" v-bind="sales_manager_select" :disabled="true" />
      </b-col>
    </b-row>
    <div class="mt-4">
      <b-button @click="createInquiry()" type="submit" :disabled="is_saving" variant="success" size="lg" class="w-100">
        {{ is_saving ? 'Creating...' : 'Create' }}
      </b-button>
    </div>
  </b-modal>
</template>

<style scoped></style>